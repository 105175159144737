import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormGroup,
  FormLabel,
  Grid2 as Grid,
  NativeSelect,
  Typography,
} from '@mui/material'
import theme from '@rfh-core/theme'

import { CustomDataGrid } from 'src/common/components'
import { GRID_MARGIN_TOP } from 'src/common/constants'
import { useApiClient } from 'src/common/providers/ApiClientProvider'
import { useUser } from 'src/common/providers/UserProvider'
import { type IVeilgroepRegelKenmerk } from 'src/common/services/client'
import { snackbarUtils } from 'src/common/utils'

import {
  byKenmerkcode,
  getRulesGridColumns,
  ruleCharacteristicToView,
} from '../lib'
import {
  addAuctionGroupRuleCharacteristic,
  getValueByCharacteristicCode,
} from '../services'
import { useAuctionGroupRuleStore } from '../stores'
import { VeilgroepRegelKenmerkView } from '../types'

type AddRuleCharacteristicProps = {
  open: boolean
  onClose: () => void
}

export function AddRuleCharacteristic({
  open,
  onClose,
}: Readonly<AddRuleCharacteristicProps>): JSX.Element {
  const CACHE_KEY = 'rule-characteristics-datagrid'
  const { apiClient } = useApiClient()
  const { i18n, t } = useTranslation()
  const user = useUser()

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultValues: VeilgroepRegelKenmerkView = {
    aanmaakDatumTijd: new Date(),
    aanmaakGebruiker: user.sub,
    mutatieDatumTijd: new Date(),
    mutatieGebruiker: user.sub,
    veilgroepRegelId: undefined,
    kenmerkCode: undefined,
    kenmerkWaardeTtm: undefined,
    kenmerkWaardeVan: undefined,
    kenmerkOmschrijving: undefined,
  }

  const {
    characteristics,
    auctionGroupRule,
    auctionGroupRuleCharacteristics,
    characteristicValues,
    setCharacteristicValues,
    addCharacteristic,
    setMustRefetchAuctionGroupRuleCharacteristics,
  } = useAuctionGroupRuleStore()

  const [inProgress, setInProgress] = useState(false)
  const [auctionGroupRuleCharacteristic, setAuctionGroupRuleCharacteristic] =
    useState<VeilgroepRegelKenmerkView>(defaultValues)

  const views = useMemo(
    () => [
      ...auctionGroupRuleCharacteristics.map(arc =>
        ruleCharacteristicToView(arc, characteristics)
      ),
    ],
    [auctionGroupRuleCharacteristics, characteristics]
  )

  const fetchCharacteristicValues = useCallback(
    async (characteristic: VeilgroepRegelKenmerkView) => {
      if (!characteristic.kenmerkCode) {
        return
      }
      try {
        setInProgress(true)
        const result = await getValueByCharacteristicCode(
          apiClient,
          characteristic.kenmerkCode
        )
        if (result.length > 0) {
          setCharacteristicValues(result)
        }
      } catch (error: any) {
        snackbarUtils.error(String(error))
      } finally {
        setInProgress(false)
      }
    },
    [apiClient, setCharacteristicValues]
  )

  const getCharacteristicOptions = useCallback(() => {
    const characteristicData = characteristics.map(characteristic => (
      <option
        key={characteristic.kenmerkCode}
        value={characteristic.kenmerkCode}
      >
        {characteristic.kenmerkCode} {characteristic.kenmerkOmschrijving}
      </option>
    ))
    characteristicData.unshift(<option key='' value=''></option>)
    return characteristicData
  }, [characteristics])

  const getCharacteristicValueOptions = useCallback(() => {
    const valueData = characteristicValues.map(characteristicValue => (
      <option
        key={characteristicValue.kenmerkWaardeCode}
        value={characteristicValue.kenmerkWaardeCode}
      >
        {characteristicValue.kenmerkWaardeCode}{' '}
        {characteristicValue.kenmerkWaardeOmschrijving}
      </option>
    ))

    valueData.unshift(<option key='' value=''></option>)
    return valueData
  }, [characteristicValues])

  const changeCharacteristic = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target
      setCharacteristicValues([])
      if (!value || value === '') {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: undefined,
          kenmerkWaardeVan: '',
          kenmerkWaardeTtm: '',
        })
      } else {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: value,
          kenmerkWaardeVan: '',
          kenmerkWaardeTtm: '',
        })
      }
    },
    [auctionGroupRuleCharacteristic, setCharacteristicValues]
  )

  const changeCharacteristicValue = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      const { name, value } = event.target
      if (!value || value === '') {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: undefined,
        })
      } else {
        setAuctionGroupRuleCharacteristic({
          ...auctionGroupRuleCharacteristic,
          [name]: value,
        })
      }
    },
    [auctionGroupRuleCharacteristic]
  )

  const validateCharacteristic = useCallback(
    (characteristic: IVeilgroepRegelKenmerk) => {
      if (characteristic.kenmerkWaardeTtm < characteristic.kenmerkWaardeVan) {
        throw new Error(t('auctionRule.invalidCharacteristicValues'))
      }
    },
    [t]
  )

  const onCreate = useCallback(async () => {
    setInProgress(true)
    const newVeilgroepRegelKenmerk: IVeilgroepRegelKenmerk = {
      ...auctionGroupRuleCharacteristic,
      veilgroepRegelId: auctionGroupRule.veilgroepRegelId,
      aanmaakDatumTijd: new Date(),
      aanmaakGebruiker: user.sub,
      mutatieDatumTijd: new Date(),
      mutatieGebruiker: user.sub,
    }

    try {
      validateCharacteristic(newVeilgroepRegelKenmerk)
      const result = await addAuctionGroupRuleCharacteristic(
        apiClient,
        newVeilgroepRegelKenmerk
      )
      if (result) {
        addCharacteristic(result)
      }
      setAuctionGroupRuleCharacteristic({
        ...defaultValues,
        veilgroepRegelId: auctionGroupRule.veilgroepRegelId,
        kenmerkOmschrijving: undefined,
        kenmerkCode: undefined,
        kenmerkWaardeVan: undefined,
        kenmerkWaardeTtm: undefined,
      })
      setMustRefetchAuctionGroupRuleCharacteristics(true)
    } catch (error: any) {
      snackbarUtils.error(String(error))
    } finally {
      setInProgress(false)
    }
  }, [
    auctionGroupRuleCharacteristic,
    auctionGroupRule.veilgroepRegelId,
    user.sub,
    validateCharacteristic,
    apiClient,
    defaultValues,
    setMustRefetchAuctionGroupRuleCharacteristics,
    addCharacteristic,
  ])

  const handleClose = () => {
    setAuctionGroupRuleCharacteristic(defaultValues)
    onClose()
  }

  const columns = useMemo(() => {
    const baseColumns = getRulesGridColumns()
    return baseColumns
  }, [])

  const rows = useMemo(() => {
    views.sort(byKenmerkcode)
    return views
  }, [views])

  const disableButton = useMemo(
    () =>
      inProgress ||
      (auctionGroupRuleCharacteristic?.kenmerkCode &&
        !auctionGroupRuleCharacteristic?.kenmerkWaardeVan),
    [
      auctionGroupRuleCharacteristic?.kenmerkCode,
      auctionGroupRuleCharacteristic?.kenmerkWaardeVan,
      inProgress,
    ]
  )

  useEffect(() => {
    const refreshCharacteristicValues = async () => {
      if (apiClient) {
        await fetchCharacteristicValues(auctionGroupRuleCharacteristic)
      }
    }
    if (open && auctionGroupRuleCharacteristic.kenmerkCode) {
      refreshCharacteristicValues()
    }
  }, [
    apiClient,
    auctionGroupRuleCharacteristic.kenmerkCode,
    open,
    fetchCharacteristicValues,
    auctionGroupRuleCharacteristic,
  ])

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent
        sx={{
          bgcolor: theme.rfhColors.grey[200],
          width: '100%',
          overflow: 'hidden',
          pb: 0,
        }}
      >
        <Typography variant='h3' mb={GRID_MARGIN_TOP * 3}>
          {t('auctionRule.title.AddCharacteristic')}
        </Typography>
        <Box component='form' sx={{ width: '100%' }} onSubmit={onCreate}>
          <FormGroup sx={{ width: '100%', mt: GRID_MARGIN_TOP }}>
            <Grid
              container
              flex={1}
              flexDirection={'column'}
              justifyContent={'flex-start'}
            >
              <Grid container mb={'0.2rem'} sx={{ maxHeight: '2.5rem' }}>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <FormLabel>{t('auctionRule.characteristicCode')}</FormLabel>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <FormControl sx={{ width: '100%' }}>
                    <NativeSelect
                      name='kenmerkCode'
                      onChange={changeCharacteristic}
                      value={auctionGroupRuleCharacteristic.kenmerkCode ?? ''}
                    >
                      {getCharacteristicOptions()}
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mb={'0.2rem'} sx={{ maxHeight: '2.5rem' }}>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <FormLabel>
                    {t('auctionRule.characteristicValueCodeFrom')}
                  </FormLabel>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <FormControl
                    sx={{
                      width: '100%',
                      bgcolor: theme.rfhColors.white,
                    }}
                  >
                    <NativeSelect
                      name='kenmerkWaardeVan'
                      onChange={changeCharacteristicValue}
                      value={
                        auctionGroupRuleCharacteristic.kenmerkWaardeVan ?? ''
                      }
                    >
                      {getCharacteristicValueOptions()}
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </Grid>
              <Grid container mb={'1rem'} sx={{ maxHeight: '2.5rem' }}>
                <Grid size={{ xs: 12, sm: 4 }}>
                  <FormLabel>
                    {t('auctionRule.characteristicValueCodeTil')}
                  </FormLabel>
                </Grid>
                <Grid size={{ xs: 12, sm: 8 }}>
                  <FormControl
                    sx={{
                      width: '100%',
                      bgcolor: theme.rfhColors.white,
                    }}
                  >
                    <NativeSelect
                      name='kenmerkWaardeTtm'
                      onChange={changeCharacteristicValue}
                      value={
                        auctionGroupRuleCharacteristic.kenmerkWaardeTtm ?? ''
                      }
                    >
                      {getCharacteristicValueOptions()}
                    </NativeSelect>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
          </FormGroup>
          <Grid
            container
            display={'flex'}
            justifyContent={'flex-end'}
            sx={{ my: GRID_MARGIN_TOP, mr: '0.1rem' }}
          >
            <Button
              variant={'contained'}
              type={'submit'}
              disabled={disableButton}
              onClick={onCreate}
            >
              {t('common.add')}
            </Button>
          </Grid>
        </Box>
        <Box position={'relative'} mt={GRID_MARGIN_TOP * 3}>
          <CustomDataGrid
            cacheKey={CACHE_KEY}
            columns={columns}
            rows={rows}
            language={i18n.language}
            getRowId={(row: VeilgroepRegelKenmerkView) =>
              row.veilgroepRegelKenmerkId
            }
            gridProps={{
              hideFooter: true,
              components: { Toolbar: null },
            }}
          />
        </Box>
        <Box display={'flex'} justifyContent={'flex-end'}>
          <DialogActions sx={{ mx: 0 }}>
            <Button variant='outlined' onClick={handleClose}>
              {t('common.ok')}
            </Button>
          </DialogActions>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
