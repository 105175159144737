import Checkbox from '@mui/material/Checkbox'
import {
  GridColDef,
  GridRenderCellParams,
  GridValueGetterParams,
} from '@mui/x-data-grid-premium'

import { t } from 'i18next'

import { ThemeConfig } from 'src/common/config'
import { getStringValue } from 'src/common/lib'
import {
  IProductclusterVeilgroepRegelCode,
  IVeilgroepIndelingRegelView,
} from 'src/common/services/client'
import type { Item } from 'src/common/types'
import { formatDate, getT, initializeTranslations } from 'src/common/utils'
import i18n from 'src/i18n'

import { HasCodeAndGeselecteerd, HasProductclusterID } from '../types'

export const calcMarginRight = (): number => {
  const lang = i18n.language
  switch (lang) {
    case 'de':
      return ThemeConfig.spacing.l * 4.58
    case 'en':
      return ThemeConfig.spacing.l * 4.22
    default:
      return ThemeConfig.spacing.l * 4.52
  }
}

export function reorderRules(
  rules: IVeilgroepIndelingRegelView[],
  start: number,
  end: number
): IVeilgroepIndelingRegelView[] {
  if (start === end) return rules

  const newRules = [...rules]
  const volgordeNumbers = newRules.map(rule => rule.volgordeNummer)
  const ruleToSwap = newRules.splice(start, 1)
  newRules.splice(end, 0, ruleToSwap[0])
  newRules.forEach(
    (rule, index) => (rule.volgordeNummer = volgordeNumbers[index])
  )
  return newRules
}

export const getMainAllocationsGridColumns = (): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'productclusterOmschrijving',
    headerName: i18n.t('auctionGroupAllocation.productClusterName'), // Omschrijving
    headerAlign: 'left',
    align: 'left',
    flex: 1,
  })
  columns.push({
    field: 'eersteVeilgroepen',
    headerName: i18n.t('auctionGroupAllocation.auctionGroups'), // Productgroepen
    headerAlign: 'left',
    align: 'left',
    flex: 4,
  })

  return columns
}

const getVeilgroepOmschrijvingMetCode = (params: GridValueGetterParams) =>
  `${params.row.veilgroepCode ?? ''} ${
    params.row.veilgroepOmschrijving ?? ''
  }`.trim()

const getVeilgroepRegelOmschrijvingMetCode = (params: GridValueGetterParams) =>
  `${params.row.veilgroepRegelNummer ?? ''} ${
    params.row.veilgroepRegelOmschrijving ?? ''
  }`.trim()

export const getMainAllocationRulesGridColumns = (
  onCheckboxClick: (
    row: IVeilgroepIndelingRegelView
  ) => (event: React.MouseEvent<HTMLButtonElement>) => void
): GridColDef[] => {
  const columns: GridColDef[] = []
  columns.push({
    field: 'actiefIndicatie',
    headerName: i18n.t('auctionGroupAllocation.active'),
    headerAlign: 'center',
    align: 'center',
    disableColumnMenu: true,
    hideable: false,
    sortable: false,
    disableExport: true,
    flex: 0.5,
    cellClassName: () => 'checkbox',
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox
        checked={params.row.actiefIndicatie}
        onClick={event => onCheckboxClick(params.row)(event)}
      />
    ),
  })
  columns.push({
    field: 'veilgroepOmschrijvingMetCode',
    headerName: i18n.t('auctionGroupAllocation.auctionGroup'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    valueGetter: getVeilgroepOmschrijvingMetCode,
  })
  columns.push({
    field: 'veilgroepRegelOmschrijvingMetCode',
    headerName: i18n.t('auctionGroupAllocation.auctionGroupRule'),
    headerAlign: 'left',
    align: 'left',
    flex: 2,
    valueGetter: getVeilgroepRegelOmschrijvingMetCode,
  })
  columns.push({
    field: 'veilgroepCodeAanvoerderAanwezig',
    headerName: i18n.t('auctionGroupAllocation.agCodePresent'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox
        checked={Boolean(params.row.veilgroepCodeAanvoerder)}
        disabled
      />
    ),
  })
  columns.push({
    field: 'codeBeperkingIndicatie',
    headerName: i18n.t('auctionGroupAllocation.codes'),
    headerAlign: 'center',
    align: 'center',
    flex: 1,
    renderCell: (params: GridRenderCellParams) => (
      <Checkbox checked={params.row.codeBeperkingIndicatie} disabled />
    ),
  })
  columns.push({
    field: 'ingangsDatum',
    headerName: i18n.t('common.startDate'),
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
    groupingValueGetter: row =>
      formatDate(row, i18n.t('common.dateFormatShort')),
  })
  columns.push({
    field: 'eindDatum',
    headerName: i18n.t('common.endDate'),
    headerAlign: 'left',
    align: 'left',
    flex: 1,
    valueFormatter: row => formatDate(row, i18n.t('common.dateFormatShort')),
    groupingValueGetter: row =>
      formatDate(row, i18n.t('common.dateFormatShort')),
  })

  return columns
}

export const isDataConsistent = (
  parentID: number,
  children: HasProductclusterID[]
): boolean =>
  children.findIndex(ars => ars.productclusterID === parentID) !== -1

const convertArrayToString = (
  items: HasCodeAndGeselecteerd[],
  length?: number
): string =>
  items
    .slice(0, length)
    .map(i => getStringValue(i.code))
    .join(', ')

export function concatCodes(items: Item<number>[]): string {
  let result = ''
  if (!items) {
    return result
  }
  const selectedItems = items.filter(i => i.selected)
  const length = selectedItems.length
  if (length <= 3) {
    result = convertArrayToString(selectedItems)
  } else {
    initializeTranslations(t)
    result = convertArrayToString(selectedItems, 3)
    result += getT()('auctionGroupAllocation.andOthers', { count: length - 3 })
  }
  return result
}

export const getItemFromListByVeilgroepRegelId = (
  list: Item<number>[],
  id: number
): Item<number> => list.find(ruleCode => ruleCode.id === id)

export const getItemFromListByRegelNummer = (
  list: Item<number>[],
  nummer: number
): Item<number> => list.find(ruleCode => ruleCode.code === nummer)

export const getDiffs = (
  ruleCodesToUpdate: IProductclusterVeilgroepRegelCode[],
  oldRuleCodesToUpdate: IProductclusterVeilgroepRegelCode[]
): IProductclusterVeilgroepRegelCode[] =>
  ruleCodesToUpdate.filter(rc => {
    //! we are interested in rulecodes that
    //! - do not exist in oldRuleCodesToUpdate
    //! - or do exist but geselecteerd differs
    const oldRuleCode = oldRuleCodesToUpdate.find(
      orc =>
        orc.productclusterVeilgroepRegelCodeId ===
          rc.productclusterVeilgroepRegelCodeId &&
        orc.code === rc.code &&
        orc.codeSoort === rc.codeSoort
    )
    if (!oldRuleCode) {
      return true
    }
    return oldRuleCode.geselecteerd === !rc.geselecteerd
  })
