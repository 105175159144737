import { ChangeEvent } from 'react'

import {
  BloemenPlantenSelect,
  FilterFieldsStack,
  FilterStack,
  LocationSelect,
} from 'src/common/components'
import type { BloemenPlanten } from 'src/common/types'

import { useAllocationsStore } from '../stores'

/**
 * AllocationsFilter is the title + search filter widget
 * @param rest (optional) any extra (e.g. styling) properties
 * @returns AllocationsFilter JSX
 */
export default function AllocationsFilter({
  ...rest
}: Readonly<{ [x: string]: any }>): JSX.Element {
  const { filter, setFilter, setMustRefetchAllocations } = useAllocationsStore()

  const changeLocation = (event: ChangeEvent<HTMLSelectElement>) => {
    setFilter({ locationCode: +event.target.value })
    setMustRefetchAllocations(true)
  }
  const changeBloemenPlanten = (event: ChangeEvent<HTMLSelectElement>) => {
    // B = bloemen, P = planten
    setFilter({ bloemenPlantenCode: event.target.value as BloemenPlanten })
    setMustRefetchAllocations(true)
  }

  return (
    <FilterStack {...rest}>
      <FilterFieldsStack>
        <BloemenPlantenSelect
          bloemenPlanten={filter.bloemenPlantenCode as BloemenPlanten}
          onChange={changeBloemenPlanten}
        />
        <LocationSelect
          locationCode={filter.locationCode}
          onChange={changeLocation}
        />
      </FilterFieldsStack>
    </FilterStack>
  )
}
